//--------- Start Home Banner Area -------------//
.home-banner-area {
	background: url(../img/home-banner-bg.png) no-repeat;
	background-size: cover;
	background-position: center center;
	max-height: 1200px;
	.fullscreen {
		@media (min-width: 1921px) {
			align-items: start !important;
			padding-top: 250px;
			max-height: 1200px !important;
		}
		@media (max-width: 768px) {
			height: 700px !important;
		}
	}
}

.home-banner-left {
	@media (max-width: 768px) {
		text-align: center;
	}
	h1 {
		color: $black;
		font-size: 48px;
		line-height: 1em;
		margin-bottom: 20px;
		span {
			font-weight: 900;
		}
		@media (max-width: 768px) {
			font-size: 36px;
		}
		br {
			@media (max-width: 767px) {
				display: none;
			}
		}
		@media (max-width: 991px) {
			font-size: 45px;
		}
		@media (max-width: 414px) {
			font-size: 40px;
		}
	}
	@media (max-width: 800px) {
		margin-top: 30px;
	}
}

.home-banner-right {
	img {
		margin-right: auto !important;
		margin-left: auto !important;
		display: block;
		@media (max-width: 991px) {
			display: none;
		}
	}
}

//--------- End Home Banner Area -------------//

.banner-area {
	background: url(../img/banner-bg.png) no-repeat center;
	background-position: right;
	min-height: 510px;
}
.banner-right {
	margin-top: 200px;
	text-align: right;
	@media (max-width: 1199px) {
		text-align: center;
	}
	// @media (max-width: 991px) {
	// 	text-align: right;
	// }
	// @media (max-width: 767px) {
	// 	text-align: center;
	// }
	h1 {
		@media (max-width: 767px) {
			font-size: 30px;
		}
	}
	p {
		@media (max-width: 767px) {
			display: none;
		}
	}
	.box {
		border-top: 1px solid $white;
		border-bottom: 1px solid $white;
		padding: 10px 18px;
		display: inline-block;
		@media (max-width: 767px) {
			padding: 7px 15px;
			margin-top: 30px;
		}
		i {
			color: $white;
			font-size: 14px;
			margin: 5px;
			font-weight: 600;
		}
		a {
			color: $white;
			font-size: 14px;
			@include transition();
			@media (max-width: 380px) {
				font-size: 13px;
			}
			&:hover {
				color: $title-color;
			}
		}
	}
}
