/* =================================== */
/*  Basic Style 
/* =================================== */

::-moz-selection {
	/* Code for Firefox */
	background-color: $primary-color;
	color: $white;
}
::selection {
	background-color: $primary-color;
	color: $white;
}
::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: #777777;
	font-weight: 300;
}
:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: #777777;
	opacity: 1;
	font-weight: 300;
}
::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: #777777;
	opacity: 1;
	font-weight: 300;
}
:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #777777;
	font-weight: 300;
}
::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #777777;
	font-weight: 300;
}
body {
	color: $text-color;
	font-family: $text-font;
	font-size: 14px;
	font-weight: 300;
	line-height: 1.625em;
	position: relative;
}
ol,
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
select {
	display: block;
}
figure {
	margin: 0;
}

a {
	@include transition(all .3s ease 0s);
}

iframe {
	border: 0;
}

a,
a:focus,
a:hover {
	text-decoration: none;
	outline: 0;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
	text-decoration: none;
	outline: 0;
}

.card-panel {
	margin: 0;
	padding: 60px;
}
/**
 *  Typography
 *
 **/
.btn i,
.btn-large i,
.btn-floating i,
.btn-large i,
.btn-flat i {
	font-size: 1em;
	line-height: inherit;
}
.gray-bg {
	background: #f9f9ff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $title-font;
	color: $title-color;
	line-height: 1.2em;
	margin-bottom: 0;
	margin-top: 0;
	font-weight: 900;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: 0;
	margin-top: 0;
	font-family: $title-font;
	font-weight: 900;
	color: $title-color;
}

h1,
.h1 {
	font-size: 36px;
}
h2,
.h2 {
	font-size: 30px;
}
h3,
.h3 {
	font-size: 24px;
}
h4,
.h4 {
	font-size: 18px;
}
h5,
.h5 {
	font-size: 16px;
}
h6,
.h6 {
	font-size: 14px;
	color: $title-color;
}

td,
th {
	border-radius: 0px;
}
/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clear {
	&::before,
	&::after {
		content: " ";
		display: table;
	}
	&::after {
		clear: both;
	}
}

.fz-11 {
	font-size: 11px;
}
.fz-12 {
	font-size: 12px;
}
.fz-13 {
	font-size: 13px;
}
.fz-14 {
	font-size: 14px;
}
.fz-15 {
	font-size: 15px;
}
.fz-16 {
	font-size: 16px;
}
.fz-18 {
	font-size: 18px;
}
.fz-30 {
	font-size: 30px;
}
.fz-48 {
	font-size: 48px !important;
}
.fw100 {
	font-weight: 100;
}
.fw300 {
	font-weight: 300;
}
.fw400 {
	font-weight: 400 !important;
}
.fw500 {
	font-weight: 500;
}
.f700 {
	font-weight: 700;
}
.fsi {
	font-style: italic;
}
// margin top
.mt--260 {
	margin-top: -260px;
}
.mt--160 {
	margin-top: -160px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-15 {
	margin-top: 15px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-25 {
	margin-top: 25px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-35 {
	margin-top: 35px;
}
.mt-40 {
	margin-top: 40px;
}
.mt-50 {
	margin-top: 50px;
}
.mt-60 {
	margin-top: 60px;
}
.mt-70 {
	margin-top: 70px;
}
.mt-80 {
	margin-top: 80px;
}
.mt-100 {
	margin-top: 100px;
}
.mt-120 {
	margin-top: 120px;
}
.mt-150 {
	margin-top: 150px;
}
// margin-left
.ml-0 {
	margin-left: 0 !important;
}
.ml-5 {
	margin-left: 5px !important;
}
.ml-10 {
	margin-left: 10px;
}
.ml-15 {
	margin-left: 15px;
}
.ml-20 {
	margin-left: 20px;
}
.ml-30 {
	margin-left: 30px;
}
.ml-50 {
	margin-left: 50px;
}
// margin-right
.mr-0 {
	margin-right: 0 !important;
}
.mr-5 {
	margin-right: 5px !important;
}
.mr-15 {
	margin-right: 15px;
}
.mr-10 {
	margin-right: 10px;
}
.mr-20 {
	margin-right: 20px;
}
.mr-30 {
	margin-right: 30px;
}
.mr-50 {
	margin-right: 50px;
}
// margin-bottom
.mb-0 {
	margin-bottom: 0px;
}
.mb-0-i {
	margin-bottom: 0px !important;
}
.mb-5 {
	margin-bottom: 5px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-15 {
	margin-bottom: 15px;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-25 {
	margin-bottom: 25px;
}
.mb-30 {
	margin-bottom: 30px;
}
.mb-40 {
	margin-bottom: 40px;
}
.mb-50 {
	margin-bottom: 50px;
}
.mb-60 {
	margin-bottom: 60px;
}
.mb-70 {
	margin-bottom: 70px;
}
.mb-80 {
	margin-bottom: 80px;
}
.mb-90 {
	margin-bottom: 90px;
}
.mb-100 {
	margin-bottom: 100px;
}
// padding-top
.pt-0 {
	padding-top: 0px;
}
.pt-10 {
	padding-top: 10px;
}
.pt-15 {
	padding-top: 15px;
}
.pt-20 {
	padding-top: 20px;
}
.pt-25 {
	padding-top: 25px;
}
.pt-30 {
	padding-top: 30px;
}
.pt-40 {
	padding-top: 40px;
}
.pt-50 {
	padding-top: 50px;
}
.pt-60 {
	padding-top: 60px;
}
.pt-70 {
	padding-top: 70px;
}
.pt-80 {
	padding-top: 80px;
}
.pt-90 {
	padding-top: 90px;
}
.pt-100 {
	padding-top: 100px;
}
.pt-120 {
	padding-top: 120px;
}
.pt-150 {
	padding-top: 150px;
}
// padding-bottom
.pb-0 {
	padding-bottom: 0px;
}
.pb-10 {
	padding-bottom: 10px;
}
.pb-15 {
	padding-bottom: 15px;
}
.pb-20 {
	padding-bottom: 20px;
}
.pb-25 {
	padding-bottom: 25px;
}
.pb-30 {
	padding-bottom: 30px;
}
.pb-40 {
	padding-bottom: 40px;
}
.pb-50 {
	padding-bottom: 50px;
}
.pb-60 {
	padding-bottom: 60px;
}
.pb-70 {
	padding-bottom: 70px;
}
.pb-80 {
	padding-bottom: 80px;
}
.pb-90 {
	padding-bottom: 90px;
}
.pb-100 {
	padding-bottom: 100px;
}
.pb-120 {
	padding-bottom: 120px;
}
.pb-150 {
	padding-bottom: 150px;
}
// padding-Right
.pr-30 {
	padding-right: 30px;
}
.pl-30 {
	padding-left: 30px;
}
.pl-90 {
	padding-left: 90px;
}

// padding
.p-40 {
	padding: 40px;
}

// floating
.float-left {
	float: left;
}
.float-right {
	float: right;
}

.text-italic {
	font-style: italic;
}
.text-white {
	color: #fff;
}
.transition {
	@include transition();
}
.section-full {
	padding: 100px 0;
}
.section-half {
	padding: 75px 0;
}
.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-rigth {
	text-align: right;
}

.flex {
	@include flexbox();
}
.inline-flex {
	@include inline-flex();
}
.flex-grow {
	@include flex-grow(1);
}
.flex-wrap {
	@include flex-wrap (wrap);
}
.flex-left {
	@include justify-content(flex-start);
}
.flex-middle {
	@include align-items(center);
}
.flex-right {
	@include justify-content(flex-end);
}
.flex-top {
	@include align-self(flex-start);
}
.flex-center {
	@include justify-content(center);
}
.flex-bottom {
	@include align-self(flex-end);
}
.space-between {
	@include justify-content(space-between);
}
.space-around {
	@include justify-content(space-around);
}
.flex-column {
	@include flex-direction(column);
}
.flex-cell {
	@include flexbox();
	@include flex-grow(1);
}
.display-table {
	display: table;
}
.light {
	color: $white;
}
.dark {
	color: $black;
}
.relative {
	position: relative;
}
.overflow-hidden {
	overflow: hidden;
}

.container {
	&.fullwidth {
		width: 100%;
	}
	&.no-padding {
		padding-left: 0;
		padding-right: 0;
	}
}
.no-padding {
	padding: 0;
}
.no-flex-xs {
	@include mobile {
		display: block !important;
	}
}

.row {
	&.no-margin {
		margin-left: 0;
		margin-right: 0;
	}
}
