
//    Font Family

$title-font	: 'Playfair Display', serif;
$text-font	: 'Roboto', sans-serif;

/*--------------------------- Color variations ----------------------*/

$primary-color	: #7c32ff;
$primary-color2	: #c738d8;
$title-color	: #222222;
$text-color		: #828bb2;

$white			: #fff;
$offwhite		: #f9f9fb;
$black			: #222;






