.section-gap {
	padding: 120px 0;
	@media (max-width: 991px) {
		padding: 60px 0px;
	}
}
.section-gap-top {
	padding-top: 120px;
	@media (max-width: 991px) {
		padding-top: 60px;
	}
}
.section-gap-bottom {
	padding-bottom: 120px;
	@media (max-width: 991px) {
		padding-bottom: 60px;
	}
}
.section-title {
	padding-bottom: 75px;
	@media (max-width: 991px) {
		padding-bottom: 40px;
	}
	h2 {
		font-size: 42px;
		color: $black;
		margin-bottom: 20px;
		@media (max-width: 768px) {
			font-size: 30px;
			margin-bottom: 15px;
		}
	}
	p {
		margin-bottom: 0;
		max-width: 620px;
		@media (max-width: 768px) {
			br {
				display: none;
			}
		}
	}
}

.gradient-bg {
	@include gradient(90deg, $primary-color 0%, $primary-color2 100%);
}

.gradient-bg-reverse {
	@include gradient(270deg, $primary-color 0%, $primary-color2 100%);
}

.p1-gradient-color {
	background: -moz-linear-gradient(0deg, rgb(62, 167, 255) 0%, rgb(66, 209, 255) 100%);
	background: -webkit-linear-gradient(0deg, rgb(62, 167, 255) 0%, rgb(66, 209, 255) 100%);
	background: -ms-linear-gradient(0deg, rgb(62, 167, 255) 0%, rgb(66, 209, 255) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.primary-btn {
	background: transparent;
	color: $white;
	font-size: 12px;
	font-weight: 500;
	position: relative;
	@include transition();
	cursor: pointer;
	text-transform: capitalize;
	letter-spacing: 1px;
	i {
		@include transform(scaleX(3));
		margin-left: 19px;
	}
	&:hover {
		color: $primary-color;
	}
}

.overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
